@import "../../styles/variables.scss";
@import "../../styles/mixin.scss";

.goDeeper {
    @include container(1440px);
    padding: 0 30px;
    margin-top: 60px;
    position: relative;
    z-index: 10;
    @media all and (max-width: 1250px) {
        margin-top: 100px;
    }
    &__title {
        margin-bottom: 60px;
        margin-left: 251px;
        color: $color-text;
        font-size: 90px;
        line-height: 90%;
        letter-spacing: -1.8px;
        @media all and (max-width: 1200px) {
            font-size: 56px;
            letter-spacing: -1.12px;
            margin-left: 155px;
            margin-bottom: 38.75px;
        }
        @media all and (max-width: 768px) {
            margin-bottom: 24px;
            margin-left: 0;
            font-size: 36px;
            letter-spacing: -0.72px;
        }
    }
    &__content {
        max-height: 440px;
        @include flex(space-between, row);
        @media all and (max-width: 768px) {
            flex-direction: column-reverse;
            max-height: 100%;
            height: auto;
        }
    }
    &__left {
        max-width: 251px;
        border-right: 1px solid rgba(122, 78, 151, 0.30);
        border-left: 1px solid rgba(122, 78, 151, 0.30);
        padding: 0 17px;
        margin-right: 30px;
        @media all and (max-width: 1200px) {
            max-width: 210px;
            width: 100%;
            padding: 0 10.6px 0;
        }
        @media all and (max-width: 768px) {
            margin-right: 0;
            margin-top: 24px;
            max-width: 100%;
            border-right: none;
            border-left: none;
            border-bottom: 1px solid rgba(122, 78, 151, 0.30);
            padding: 0 0 32px 0;
        }
        p {
            font-size: 32px;
            letter-spacing: -0.64px;
            margin-bottom: 20px;
            @media all and (max-width: 1200px) {
                max-width: 137px;
                font-size: 20px;
                letter-spacing: -0.4px;
                margin-bottom: 11.5px;
                
            }
            @media all and (max-width: 768px) {
                margin-bottom: 17px;
                max-width: 217px;
                font-size: 16px;
                letter-spacing: -0.32px;
            }
        }
        a {
            width: 217px;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 8px;
            font-size: 16px;
            font-weight: 500;
            line-height: 100%;
            letter-spacing: -0.16px;
            padding: 16px 0;
            border-radius: 8px;
            background: $background-btn-gradient;
            @media all and (max-width: 1200px) {
                font-size: 14px;
                letter-spacing: -0.16px;
                gap: 5px;
                padding: 10px 0;
                width: 100%;
            }
            @media all and (max-width: 768px) {
                max-width: 217px;
                padding: 16px 0;
            }
            img {
                @media all and (max-width: 1200px) {
                    width: 11px;
                }
            }
        }
    }
    &__right {
        position: relative;
        padding: 60px 20px 63px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 8px;
        background: rgba(15, 24, 42, 0.30);
        @media all and (max-width: 768px) {
            padding: 24px 12px;
        }
        .videos {
            max-width: 737px;
        }
        .play {
            position: absolute;
            top: 0;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            img {
                cursor: pointer;
            }
        }
    }
}
