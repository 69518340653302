$color-text: #fff;
$color-text-btn: #F1EEF1;
$color-text-counter: #7A4E97;
$bg-btn-hover: #3B194D;
$background-btn-gradient: linear-gradient(52deg, #200446 -28.98%, #4F2665 32.97%, #A967A0 111.25%);

$body-background-dark: #000;
$light-color: #070707;


:root {
	--body-background-dark: #{$body-background-dark};
}
