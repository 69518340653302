@import "../../styles/variables.scss";
@import "../../styles/mixin.scss";

.deposit {
    background-color: #0D0D0D;
    position: relative;
    padding-top: 80px;

    @media screen and (max-width: 860px) {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 760px;
    }
    &__title {
        position: relative;
        z-index: 2;
        max-width: 535px;
        font-size: 48px;
        line-height: 90%;
        letter-spacing: -0.96px;
        margin-left: 251px;
        @media screen and (max-width: 1200px) {
            margin-left: 156px;
            max-width: 334px;
            font-size: 30px;
            line-height: 90%;
            letter-spacing: -0.6px;
        }
        @media screen and (max-width: 860px) {
            margin-left: 89px;
            font-size: 24px;
            line-height: 90%;
            letter-spacing: -0.48px;
        }
        @media screen and (max-width: 500px) {
            margin-left: 32px;
            width: 100%;
        }
        @media screen and (max-width: 380px) {
            margin-left: 16px;
            width: 100%;
        }
        @media screen and (max-width: 360px) {
            margin-left: 0;
            max-width: 280px;
        }
    }
    &__content {
        position: relative;
        z-index: 1;
        margin-top: 44px;
        padding: 16px;
        @include flex(space-between, row);
        @media screen and (max-width: 1200px) {
            margin-top: 27px;
        }
        @media screen and (max-width: 860px) {
            margin-top: 32px;
        }
    }
    &__left {
        max-width: 540px;
        width: 100%;
        margin-top: 60px;
        @media screen and (max-width: 1200px) {
            margin-top: 38px;
        }
        @media screen and (max-width: 860px) {
            margin-top: 0;
        }
        &-des {
            font-size: 24px;
            line-height: 110%;
            letter-spacing: -0.48px;
            @media screen and (max-width: 1200px) {
                max-width: 393px;
                font-size: 16px;
                line-height: 110%;
                letter-spacing: -0.32px;
            }
        }
        &-count {
            
            color: $color-text-counter;
            font-size: 200px;
            font-weight: 400;
            line-height: 90%;
            letter-spacing: -4px;
            @media screen and (max-width: 1200px) {
                font-size: 112px;
                letter-spacing: -2.24px;
            }
        }
        &-text,  &-text-none  {
            font-size: 24px;
            line-height: 90%;
            letter-spacing: -0.48px;
            @media screen and (max-width: 1200px) {
                font-size: 16px;
                letter-spacing: -0.32px;
            }
            
        }
        &-text-none {
            margin-top: 50px;
        }
    }
    &__bg {
        position: absolute;
        z-index: 0;
        top: 0;
        width: 100%;
        @media screen and (max-width: 860px) {
            position: relative;
            width: 300px;
            height: 350px;
            overflow: hidden;
        }
        video {
            position: relative;
            z-index: 1;
            width: 100%;
            @media screen and (max-width: 860px) {
                width: 900px;
                position: absolute;
                left: -183%;
                top: -30%;
            }
            @media screen and (max-width: 340px) {
                top: -35%;
            }
        }
    }
}