@import "../../styles/variables.scss";
@import "../../styles/mixin.scss";


.running-line {
  width: 100%;
  overflow: hidden;
  position: relative;
  @include grid(20px);
  @media screen and (max-width: 950px) {
    gap: 8px;
}
  span > span {
    display: inline-block;
    padding: 0 8px;
    position: relative;
    text-transform: uppercase;
  }
  img {
    margin: 0 5px;
  }
}
