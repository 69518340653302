@import "../../styles/variables.scss";
@import "../../styles/mixin.scss";

.joinToday {
    @include container(1440px);
    padding: 0 30px;
    margin-top: 180px;
    @media all and (max-width: 1200px) {
        margin-top: 150px;
    }
    @media all and (max-width: 860px) {
        margin-top: 80px;
    }
    @media all and (max-width: 768px) {
        padding: 0 16px;
    }
   
    &__title {
        font-size: 200px;
        line-height: 110%; 
        letter-spacing: -4px;
        text-align: center;
        margin-bottom: -95px;
        @media all and (max-width: 1200px) {
            font-size: 125px;
            line-height: 90%;
            letter-spacing: -2.5px;
            margin-bottom: -57px;
        }
        @media all and (max-width: 860px) {
            font-size: 64px;
            line-height: 110%;
            letter-spacing: -1.28px;
            margin-bottom: -32px;
        }
    }

    &__content {
        @include flex(space-between, row);
        padding: 30px;
        border-radius: 8px;
        background: linear-gradient(10deg, rgba(32, 4, 70, 0.30) -6.15%, rgba(79, 38, 101, 0.30) 44.7%, rgba(169, 103, 160, 0.30) 116.9%);
        backdrop-filter: blur(10px);
        height: 698px;
        @media all and (max-width: 1200px) {
            height: 550px;
        }
        @media all and (max-width: 860px) {
            border-radius: 2.98px;
            background: linear-gradient(10deg, rgba(32, 4, 70, 0.30) -6.15%, rgba(79, 38, 101, 0.30) 44.7%, rgba(169, 103, 160, 0.30) 116.9%);
            backdrop-filter: blur(3.724637746810913px);
            display: flex;
            align-items: center;
            height: 550px;
        }
        @media all and (max-width: 700px) {
            flex-direction: column;
            height: 660px;
            padding-top: 40px;
        }
        @media all and (max-width: 390px) {
            height: 565px;
            padding: 30px 16px;
        }
        
    }
    &__info {
        max-width: 435px;
        margin: auto 0;
        @media all and (max-width: 860px) {
            margin: 0;
        }
    }
    &__subTitle {
        color: rgba(255, 255, 255, 0.80);
        font-size: 68px;
        line-height: 100%;
        letter-spacing: -1.36px;
        @media all and (max-width: 1200px) {
            font-size: 42px;
            line-height: 90%;
            letter-spacing: -0.84px;
            max-width: 249px;
        }
        @media all and (max-width: 860px) {
            font-size: 24px;
            letter-spacing: -0.48px;
        }
    }
    &__store {
        @include flex(flex-start, row);
        gap: 8px;
        margin-top: 35px;
        @media all and (max-width: 860px) {
            margin-top: 24px;
        }
        &-appStore, 
        &-play {
            padding: 8px 12px;
            border: 1px solid #FFF;
            border-radius: 8px;
            display: flex;
            gap: 8px;
            img {
                width: 30px;
                @media all and (max-width: 450px) {
                    width: 20px;
                }
            }
            p {
                font-family: SF Pro Display;
                font-size: 10px;
                font-weight: 400;
                
            }
            h3 {
                font-family: SF Pro Display;
                font-size: 16px;
                font-weight: 500;
                
            }
        }
    }
    &__img {
        transform: translate(-90px, 75px);
        @media all and (max-width: 1200px) {
            transform: translate(-90px, 51px);
        }
        @media all and (max-width: 860px) {
            transform: translate(-50px, 41px);
        }
        @media all and (max-width: 750px) {
            transform: translate(0px, 41px);
        }
        @media all and (max-width: 730px) {
            transform: translate(0px, 33px);
        }
        @media all and (max-width: 390px) {
            transform: translate(0px, 28px);
        }
       img {
            @media all and (max-width: 1200px) {
                max-width: 300px;
            }
            @media all and (max-width: 390px) {
                max-width: 250px;
            }
       }
    }
}