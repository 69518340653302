@import "../../styles/variables.scss";
@import "../../styles/mixin.scss";

.moreThan {
    @include flex(space-between, row);
    height: auto;
    @media screen and (max-width: 860px) {
        flex-direction: column;
        align-items: center;
        width: 100%;
        overflow: auto;
    }

    &__left {
        position: relative;
        max-width: 630px;
        width: 100%;
        
        @media screen and (max-width: 1200px) {
            max-width: 375px;
        }
        @media screen and (max-width: 860px) {
            margin-bottom: 100px;
        }
        &-bg {
            width: 100%;
            height: 100%;
            position: absolute;
            z-index: 0;
            top: 0;
            left: 0;
            background: url('../../assets/icon/icon-bg-moreThan.svg') no-repeat 10px 20px;
            background-size: contain;
            @media screen and (max-width: 860px) {
                width: 145%;
                height: 120%;
                left: -45%;
            }
        }
        p {
            line-height: 110%;
            letter-spacing: -0.96px;
            position: relative;
            z-index: 2;
        }
        &-title {
            font-size: 48px;
            @media screen and (max-width: 1200px) {
                font-size: 30px;
                letter-spacing: -0.6px;
            }
            @media screen and (max-width: 860px) {
                font-size: 32px;
                letter-spacing: -0.64px;
            }
        }
        &-count {
            font-size: 180px;
            margin-top: 16px;
            color: $color-text-counter;
            font-size: 180px;
            line-height: 90%;
            letter-spacing: -3.6px;
            @media screen and (max-width: 1200px) {
                font-size: 112px;
                letter-spacing: -2.24px;
                margin-top: 10px;
            }
            @media screen and (max-width: 860px) {
                font-size: 120px;
                letter-spacing: -2.4px;
            }
        }
        &-subTitle {
            font-size: 48px;
            @media screen and (max-width: 1200px) {
                font-size: 30px;
                letter-spacing: -0.6px;
            }
            @media screen and (max-width: 860px) {
                font-size: 32px;
                line-height: 110%;
                letter-spacing: -0.64px;
            }
        }
        &-info {
            max-width: 347px;
            margin-top: 106px;
            line-height: 110%;
            font-size: 24px;
            letter-spacing: -0.48px;
            @media screen and (max-width: 1200px) {
                font-size: 15px;
                letter-spacing: -0.3px;
                margin-top: 67px;
            }
            @media screen and (max-width: 860px) {
                font-size: 20px;
                letter-spacing: -0.4px;
                margin-top: 32.64px;

            }
        }
        &-btn {
            padding: 16px 50.5px;
            border-radius: 8px;
            background: $background-btn-gradient;
            font-family: SF Pro Display;
            font-size: 16px;
            font-weight: 500;
            line-height: 100%;
            letter-spacing: -0.16px;
            margin-top: 24px;
            position: relative;
            z-index: 2;
            @media screen and (max-width: 1200px) {
                margin-top: 15px;
            }
        }
    }
    &__right {
        max-width: 690px;
        width: 100%;
        overflow: hidden;
        @media screen and (max-width: 1200px) {
            max-width: 500px;
        }
        @media screen and (max-width: 500px) {
            max-width: 432px;
        }
        &-list-active {
            height: 1219px;
            @media screen and (max-width: 1343px) {
                height: 1250px;
            }
            @media screen and (max-width: 1248px) {
                height: 1284px;
            }
            @media screen and (max-width: 1200px) {
                height: 1087px;
            }
            @media screen and (max-width: 862px) {
                height: 1119px;
            }
            @media screen and (max-width: 860px) {
                height: 1088px;
            }
            @media screen and (max-width: 500px) {
                height: 1152px;
            }
            @media screen and (max-width: 480px) {
                height: 1120px;
            }
            @media screen and (max-width: 459px) {
                height: 1152px;
            }
            @media screen and (max-width: 420px) {
                height: 1184px;
            }
            @media screen and (max-width: 411px) {
                height: 1216px;
            }
            @media screen and (max-width: 396px) {
                height: 1247px;
            }
        }
        &-list {
            height: 455px;
            overflow: hidden;
            @media screen and (max-width: 1200px) {
                height: 327px;
            }
            @media screen and (max-width: 500px) {
                height: 342px;
            }
            @media screen and (max-width: 411px) {
                height: 374px;
            }
        }
        &-list, 
        &-list-active {
            transition: all .2s linear;
            li {
                border-top: 0.5px solid rgba(113, 71, 141, 0.80);
                background: rgba(113, 71, 141, 0.10);
                padding: 12px 22px;
                display: flex;
                gap: 2px;
                align-items: center;
                @media screen and (max-width: 1200px) {
                    padding: 8.12px 10px;
                }
                @media screen and (max-width: 480px) {
                    padding: 8.12px 4px;
                }
                h3 {
                    font-size: 16px;
                    line-height: 110%;
                    letter-spacing: -0.4px;
                    margin-right: 20px;
                    @media screen and (max-width: 1200px) {
                        font-size: 12px;
                        line-height: 110%;
                        letter-spacing: -0.24px;
                        margin-right: 10.5px;

                    }
                }
                .moreThan__right-list-block {
                    display: flex;
                    align-items: center;
                    gap: 2px;
                    flex-wrap: wrap;
                   div {
                    height: 30px;
                    padding: 5px 4px;
                    display: flex;
                    align-items: center;
                    gap: 2px;
                    border-radius: 4px;
                    background: rgba(113, 71, 141, 0.20);

                    img {
                        width: auto;
                        max-height: 24px;
                    }
                    p {
                        color: rgba(255, 255, 255, 0.80);
                        font-family: SF Pro Display;
                        font-size: 16px;
                        font-weight: 400;
                        letter-spacing: -0.32px;
                        @media screen and (max-width: 1200px) {
                            font-size: 10px;
                            letter-spacing: -0.2px;
                        }
                    }
                   }
                  
                }
            }
        }
        &-btn {
            padding: 19px 24px;
            text-align: start;
            width: 100%;
            border-top: 0.5px solid rgba(113, 71, 141, 0.80);
            background: $background-btn-gradient;
            font-size: 20px;
            line-height: 110%;
            letter-spacing: -0.4px;
            @media screen and (max-width: 1200px) {
                font-size: 12px;
                letter-spacing: -0.24px;
            }
        }
    }
}