@import "../../styles/variables.scss";
@import "../../styles/mixin.scss";

.exchange {
    @include container(1440px);
    @include flex(space-between, row);
    margin-top: 100px;
    padding: 24px 30px 73px;
    @media all and (max-width: 1200px) {
        flex-direction: column;
        align-items: center;
    }
    @media all and (max-width: 768px) {
        margin-top: 64px;
        padding: 0 16px;
        @include grid(0);
        max-width: 600px;
        position: relative;
        z-index: 10;
    }
    &__left {
        @include grid(36px);
        max-width: 588px;
        max-height: 306px;
        margin-top: 70px;
        @media all and (max-width: 1200px) {
            margin-bottom: 70px;
            max-width: 700px;
        }
        @media all and (max-width: 768px) {
            margin-top: 0;
            @include grid(0);
        }

        h2 {
            font-size: 90px;
            line-height: 90%;
            letter-spacing: -1.8px;
            @media all and (max-width: 1350px) {
                font-size: 42px;
                line-height: 110%;
                letter-spacing: -0.84px;
            }
            @media all and (max-width: 768px) {
                font-size: 36px;
                line-height: 90%;
                letter-spacing: -0.72px;
            }
        }
        p {
            font-size: 32px;
            line-height: 110%;
            letter-spacing: -0.64px;
            @media all and (max-width: 1350px) {
                font-size: 24px;
                line-height: 90%;
                letter-spacing: -0.6px;
            }
            @media all and (max-width: 768px) {
                font-size: 16px;
                line-height: 110%;
                letter-spacing: -0.32px;
                margin: 12px 0 24px;
            }
        }
        &-btn {
            button {
                padding: 16px 50.5px;
                border-radius: 8px;
                background: $background-btn-gradient;
                font-family: SF Pro Display;
                font-size: 16px;
                font-weight: 500;
                line-height: 100%;
                letter-spacing: -0.16px;
            } 
        }
    }
    &__right {
        max-width: 700px;
        img {
            width: 100%;
        }
    }
}