@import "../../styles/variables.scss";
@import "../../styles/mixin.scss";

.container {
    width: 100%;
    position: fixed;
    z-index: 1000;
    top: 30px;
}

.header {
    @include container(1440px);
    padding: 0 30px ;
    @include flex;
    align-items: center;
    background: none;
    @media screen and (max-width: 992px) {
        padding: 0 20px;
    }
    @media all and (max-width: 673px) {
        padding: 0 16px;
    }
    &__logo {
        padding: 3px 14px;
        border-radius: 8px;
        background: rgba(255, 255, 255, 0.10);
        backdrop-filter: blur(10px);
        img {
            @media all and (max-width: 992px) {
                width: 180.701px;
            }
            @media all and (max-width: 673px) {
                width: 166.001px;
            }
        }
    }
    &__btns {
        display: flex;
        align-items: center;
        gap: 8px;
        @media all and (max-width: 673px) {
            display: none;
        }
        button {
            font-family: SF Pro Display;
            padding: 16px 32px;
            border-radius: 8px;
            text-align: center;
            font-size: 1rem;
            font-weight: 500;
            line-height: 100%;
            color: $color-text-btn;
            @media all and (max-width: 992px) {
                padding: 12px 23px;
                font-size: 12px;
            }
        }
    }

    &__signUp {
        background: rgba(255, 255, 255, 0.1);
        &:hover {
            background: #282828;
        }
        &:active {
            background: #707070;
        }
        
    }
    &__signIn {
        background: linear-gradient(52deg, #200446 -28.98%, #4F2665 32.97%, #A967A0 111.25%);
        &:hover {
            background: linear-gradient(40deg, #200446 -34.35%, #4F2665 74.67%, #A967A0 212.45%);
        }
        &:active {
            background: #A967A0;
        }
    }
    &__burger-block{
        position: relative;
        display: none;
        width: 28px;
        height: 20px;
        @media all and (max-width: 673px) {
            display: block;
        }
        .header__burger {
            cursor: pointer;
            height: 20px;
            @media all and (max-width: 673px) {
                @include grid(7px);
            }
            position: absolute;
            z-index: 1000;
           
            div {
                background: var(--White, #FAFAFA); 
                width: 28px;
                height: 2px;
                transition: 0.1s;
            }
            .header__burger-lineTop {
                transform: rotate(45deg);
                position: absolute;
                top: 50%;
            }
            .header__burger-lineMiddle {
                opacity: 0;
            }
            .header__burger-lineBottom {
                transform: rotate(-45deg);
                position: absolute;
                top: 50%;
            }
        }
        
    }
    &__mobile {
        background: linear-gradient(37deg, #200446 23.02%, #4F2665 94.78%, #A967A0 185.46%);
        width: 100%;
        height: 100vh;
        position: absolute;
        top: -30px;
        left: 0;
        padding: 120px 36px 64px;
        @include flex(space-between, column);
        &-list {
            @include grid(20px);
            margin-bottom: 32px;
            a {
                font-size: 24px;
                font-weight: 500;
                line-height: 100%;
                letter-spacing: -0.5px;
            }
            &-lang {
                border-top: 1px solid #25314B;
                padding-top: 16px;
                div {
                    width: 62px;
                }
            }   
        }
        &-bottom {
            @include flex(space-between, row);
            gap: 8px;
            padding-top: 20px;
            button {
                border-radius: 8px;
                padding: 16px 0;
                width: 100%;
                font-size: 16px;
                font-weight: 500;
                line-height: 100%;
                letter-spacing: -0.16px;
            }
        }
    }
}
