@import "../../styles/variables.scss";
@import "../../styles/mixin.scss";

.benefits {
    &__title {
        font-size: 200px;
        line-height: 110%; 
        letter-spacing: -4px;
        text-align: center;
        margin-bottom: -95px;
        @media screen and (max-width: 1200px) {
            margin-bottom: -58px;
            font-size: 125px;
            line-height: 110%;
            letter-spacing: -2.5px;
        }
        @media screen and (max-width: 860px) {
            margin-bottom: -30px;
            font-size: 64px;
            letter-spacing: -1.28px;
        }
    }
    &__info {
        padding: 80px 0;
        @include container(755px);
        @media screen and (max-width: 1200px) {
            @include container(510px);
            padding: 50px 0;
        }
        @media screen and (max-width: 860px) {
            padding: 52px 0 16px;
        }
        @media screen and (max-width: 600px) {
            @include container(542px);
            padding: 52px 16px 16px 16px;
        }
    }
    &__content {
        @include flex(space-between, column);
        border-radius: 8px;
        background: linear-gradient(10deg, rgba(32, 4, 70, 0.30) -6.15%, rgba(79, 38, 101, 0.30) 44.7%, rgba(169, 103, 160, 0.30) 116.9%);
        backdrop-filter: blur(10px);

        &-subTitle {
            max-width: 568px;
            font-size: 24px;
            line-height: 110%;
            letter-spacing: -0.48px;
            margin-bottom: 32px;
            @media screen and (max-width: 1200px) {
                max-width: 404px;
                font-size: 16px;
                letter-spacing: -0.32px;
                margin-bottom: 17.5px;
            }
            @media screen and (max-width: 860px) {
                font-size: 14px;
                margin-bottom: 12px;
                letter-spacing: -0.28px;
            }
        }
        &Block {
            @include flex(space-between, row);
            @media screen and (max-width: 1200px) {
                justify-content: center;
                gap: 5px;
            }
            @media screen and (max-width: 860px) {
                justify-content: start;
                flex-wrap: wrap;
            }
            div {
                display: flex;
                align-items: center;
                padding: 12px 12px;
                border-radius: 8px;
                border: 1px solid rgba(113, 71, 141, 0.20);
                background: rgba(255, 255, 255, 0.20);
                backdrop-filter: blur(5px);
                @media screen and (max-width: 1200px) {
                    padding: 8.75px 7.5px;
                }
                @media screen and (max-width: 860px) {
                    padding: 5.20px 4.4px;
                }
                p {
                    font-size: 20px;
                    line-height: 100%;
                    letter-spacing: -0.4px;
                    margin-right: 10px;
                    @media screen and (max-width: 1200px) {
                        font-size: 12px;
                        letter-spacing: -0.24px;
                        margin-right: 6px;
                    }
                }
            }
            img {
                width: 32px;
                @media screen and (max-width: 1200px) {
                    width: 26px;
                }
            }
        }
    }
}