@import "../../styles/variables.scss";
@import "../../styles/mixin.scss";

.container {
    position: relative;
    height: 810px;
    padding-top: 94px;
    @media all and (max-width: 1300px) {
        height: auto;
    }
    @media screen and (max-width: 768px) {
        overflow: hidden;
        position: static;

    }
    .mainScreen__gif {
        width: 100%;
        position: absolute;
        top: 0;
        display: flex;
        video {
            @include container(1440px);
            margin: 0 auto;
            @media screen and (max-width: 768px) {
                margin-left: -25%;
                width: 1125px;
            }
            @media screen and (max-width: 690px) {
                margin-left: -35%;
            }
            @media screen and (max-width: 640px) {
                margin-left: -40%;
            }
            @media screen and (max-width: 600px) {
                margin-left: -45%;
            }
            @media screen and (max-width: 580px) {
                margin-left: -50%;
            }
            @media screen and (max-width: 540px) {
                margin-left: -55%;
            }
            @media screen and (max-width: 500px) {
                margin-left: -65%;
            }
            @media screen and (max-width: 470px) {
                margin-left: -75%;
            }
            @media screen and (max-width: 440px) {
                margin-left: -80%;
            }
            @media screen and (max-width: 420px) {
                margin-left: -85%;
            }
            @media screen and (max-width: 400px) {
                margin-left: -90%;
            }
            @media screen and (max-width: 380px) {
                margin-left: -100%;
            }
            @media screen and (max-width: 360px) {
                margin-left: -110%;
            }
        }
        @media screen and (max-width: 768px) {
            overflow: hidden;
        }
    }
    .mainScreen {
        @include container(1440px);
        padding: 0 30px;
        max-height: 603px;
        height: 100%;
        position: relative;
        z-index: 50;
        @include flex(space-between, column);
        @media screen and (max-width: 1300px) {
            height: 60vh;
        }
        @media screen and (max-width: 1100px) {
            height: 450px;
        }
        @media screen and (max-width: 992px) {
            padding: 0 20px;
        }
        @media screen and (max-width: 950px) {
            height: 400px;
        }
        @media screen and (max-width: 850px) {
            height: 350px;
        }
        @media screen and (max-width: 768px) {
            height: 525px;
        }
        @media screen and (max-width: 673px) {
            justify-content: flex-end;
            padding: 0 16px;
            max-height: 670px;
            height: 670px;
        }
        &__middle {
            div:last-child {
                @include flex(space-between);
                margin-bottom: 24px;
                @media screen and (max-width: 673px) {
                    flex-direction: column;
                }
                h2 {
                    max-width: 690px;
                    font-size: 90px;
                    line-height: 90%;
                    letter-spacing: -1.8px;
                    margin-bottom: 24px;
                    @media all and (max-width: 992px) {
                        font-size: 56px;
                        max-width: 430px;
                    }
                    @media screen and (max-width: 768px) {
                        font-size: 56px;
                    }
                }
                ul {
                    text-align: end;
                    opacity: 0.8;
                    @media screen and (max-width: 673px) {
                        display: none;
                    }
                    li {
                        font-size: 14px;
                        line-height: 140.5%;
                        letter-spacing: -0.28px;
                        text-transform: uppercase;
                        font-style: normal;
                        font-weight: 400;
                        @media screen and (max-width: 820px) {
                            font-size: 12px;
                        }
                    }
                }

            }
            &-btn {
                max-width: 150px;
                a {
                    cursor: pointer;
                    font-family: SF Pro Display;
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 100%;
                    letter-spacing: -0.16px;
                    padding: 16px 36px;
                    border-radius: 8px;
                    background: $background-btn-gradient;
                }
            }
        }
        &__title {
            max-width: 240px;
            font-size: 14px;
            font-weight: 400;
            line-height: 110%;
            letter-spacing: -0.28px;
            text-transform: uppercase;
            @media screen and (max-width: 673px) {
                margin-bottom: 11px;
                font-size: 12px;
                opacity: 0.5;
            }
        }
        &__line {
            display: none !important;
            margin-top: 30px;
            position: relative;
            @include grid(12px);
            div:last-child {
                margin: 0 !important;
                position: relative;
                z-index: 5;
            }
            @media screen and (max-width: 673px) {
                width: 100%;
                display: flex !important;
                position: relative;
            }
        }
        &__line-bg {
            display: none;
            @media screen and (max-width: 673px) {
                width: 110%;
                @include flex(space-between,);
                position: absolute;
                left: -10px;
                z-index: 10;
            }
            div {
                width: 25%;
                height: 54px;
            }
            div:first-child {
                background: linear-gradient(270deg, rgba(0, 0, 0, 0.00) -43.33%, #070809 100%);
            }
            div:last-child {
                transform: rotate(180deg);
                background: linear-gradient(270deg, rgba(0, 0, 0, 0.00) -43.33%, #070809 100%);
            }
        }
    }
}
