@import "../../styles/variables.scss";
@import "../../styles/mixin.scss";

.notFound {
  width: 100%;
  margin: 0 auto;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  h1 {
    color: var(#{--text-color-defaut});
  }
  .emptyBlock {
    width: 1200px;
    height: 54px;
  }
}
