@import "../../styles/variables.scss";
@import "../../styles/mixin.scss";

.statistics {
    padding: 100px 0 ;
    @media screen and (max-width: 1200px) {
        margin-top: 120px;
        padding: 71px 20px;
    }
    @media screen and (max-width: 860px) {
        margin-top: 36px;
        padding: 57px 20px 69px;
    }
    &__title {
        text-align: center;
        margin-bottom: 24px;
        font-size: 48px;
        line-height: 110%;
        letter-spacing: -0.96px;
        @media screen and (max-width: 1200px) {
            font-size: 30px;
            letter-spacing: -0.6px;
            margin-bottom: 15px;
        }
        @media screen and (max-width: 860px) {
            font-size: 36px;
            letter-spacing: -0.72px;
        }
    }
    &__theme {
        @include container(433px);
        @include flex(space-between, row);
        padding: 4px 8px 3px 8px;
        align-items: center;
        gap: 8px;
        margin-bottom: 44px;
        @media screen and (max-width: 1200px) {
            gap: 5px;
            max-width: 280px;
            margin-bottom: 27px;
        }
        @media screen and (max-width: 860px) {
            max-width: 380px;
            justify-content: space-between;
            margin: 36px auto 30px;
        }
        div {
            cursor: pointer;
        }
        p {
            @include flex(space-between, row);
            align-items: center;
            gap: 8px;
            font-size: 16px;
            line-height: 110%;
            font-weight: 400;
            letter-spacing: -0.32px;
            @media screen and (max-width: 1200px) {
                gap: 5px;
                font-size: 10px;
                letter-spacing: -0.2px;
            }
            @media screen and (max-width: 860px) {
                font-size: 12px;
                letter-spacing: -0.24px;
            }
        }
        img {
            @media screen and (max-width: 1200px) {
                width: 18px;
            }
        }
    }
    &__img {
        @include container(988px);
        @media screen and (max-width: 1200px) {
           max-width: 617.5px;
        }
        img {
            width: 100%;
            border-radius: 8px;
        }
    }
    &__text {
        max-width: 723px;
        margin: 60px auto 24px;
        color:  #FAFAFA;
        text-align: center;
        font-size: 24px;
        line-height: 110%;
        letter-spacing: -0.48px;
        @media screen and (max-width: 1200px) {
            margin: 27px auto 10px;
            font-size: 16px;
            letter-spacing: -0.32px;
        }
        @media screen and (max-width: 860px) {
            margin: 46px 0 24px;
            
        }
    }
    &__btn {
        text-align: center;
        button {
            max-width: 300px;
            width: 100%;
            padding: 16px 0;
            border-radius: 8px;
            background: linear-gradient(52deg, #200446 -28.98%, #4F2665 32.97%, #A967A0 111.25%);
            color: #F1EEF1;
            font-size: 16px;
            font-weight: 500;
            line-height: 100%;
            letter-spacing: -0.16px;
            @media screen and (max-width: 1200px) {
                max-width: 187.5px;
                padding: 10px 0;
            }
            @media screen and (max-width: 860px) {
                max-width: 300px;
                padding: 16px 0;
            }
        }
    }
}