@import "../styles/variables.scss";
@import "../assets/fonts/stylesheet.css";
@import url('https://fonts.cdnfonts.com/css/sf-pro-display');


* {
	font-family: 'HelveticaNeueCyr';
	font-weight: 400;
	box-sizing: border-box;
	padding: 0;
	margin: 0;
	color: $color-text;
}

.App {
	width: 100%;
	overflow: hidden;
	height: 100%;
}

html,
body {
	padding: 0;
	margin: 0;
	font-size: 16px;
	position: relative;
	background: var(#{--body-background-dark});
	scroll-behavior: smooth;
}

a {
	color: inherit;
	text-decoration: none;
}

ul,
li {
	list-style: none;
}
button {
	cursor: pointer;
	border: none;
	color: $color-text-btn;
}
button:hover {
	background: linear-gradient(40deg, #200446 -34.35%, #4F2665 74.67%, #A967A0 212.45%);
}
button:active {
	background: #A967A0;
}
/* Custom swiper */

// .swiper-button-prev::after,
// .swiper-button-next::after {
// 	font-size: 45px;
// }
