@import "./variables.scss";

@mixin container($maxWidth) {
	width: 100%;
	max-width: $maxWidth;
	margin: 0 auto;
}

@mixin nav {
	font-size: 1rem;
	font-weight: 500;
}
@mixin active {
	color: var(#{--text-color-defaut});
	font-weight: 500;
}

@mixin text {
	color: var(#{--text-color-defaut});
	font-weight: 500;
	font-size: 1rem;
}
@mixin title($fontSize: 2rem) {
	color: var(#{--text-color-defaut});
	font-weight: 700;
	font-size: $fontSize;
}

@mixin flex($justify: space-between, $flexDirection: row) {
	display: flex;
	justify-content: $justify;
	flex-direction: $flexDirection;
}
@mixin grid($gap: 0) {
	display: grid;
	gap: $gap;
}
