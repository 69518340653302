@import "../../styles/variables.scss";
@import "../../styles/mixin.scss";

.location {
    background: #0C0C0C;
    @include container(1440px);
    @include flex(space-between, row);
    position: relative;
    margin-top: 100px;
    padding: 0 30px;
    height: 810px;
    @media all and (max-width: 1250px) {
        height: 600px;
    }
    @media all and (max-width: 890px) {
        height: 560px;
    }
    @media all and (max-width: 750px) {
        justify-content: center;
        height: auto;
        margin-top: 64px;
        padding-bottom: 32px;
    }
    &__left {
        position: relative;
        z-index: 10;
        max-width: 588px;
        @media all and (max-width: 1250px) {
            max-width: 314px;
        }
        @media all and (max-width: 750px) {
            @include flex(space-between, row);
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            max-width: 100%;
            height: 694px;
        }
        h2 {
            margin-top: 70px;
            font-size: 90px;
            line-height: 90%;
            letter-spacing: -1.8px;
            @media all and (max-width: 1250px) {
                font-size: 56px;
                letter-spacing: -1.12px;
            }
            @media all and (max-width: 750px) {
                font-size: 36px;
                letter-spacing: -0.72px;
                margin-top: 32px;
            }
        }
        p {
            margin: 60px 0 44px 0;
            max-width: 502px;
            font-size: 32px;
            line-height: 110%;
            letter-spacing: -0.64px;
            @media all and (max-width: 1250px) {
                font-size: 20px;
                letter-spacing: -0.4px;
                margin: 38.75px 0 27px 0;
            }
            @media all and (max-width: 750px) {
                max-width: 343px;
                font-size: 16px;
                letter-spacing: -0.32px;
            }
        }
        &-btn {
            button {
                padding: 16px 50.5px;
                border-radius: 8px;
                background: $background-btn-gradient;
                font-family: SF Pro Display;
                font-size: 16px;
                font-weight: 500;
                line-height: 100%;
                letter-spacing: -0.16px;
            } 
        }
    }
    &__right {
        @include container(1160px);
        height: 810px;
        position: absolute;
        left: 30%;
        @media all and (max-width: 1250px) {
            left: 20%;
        }
        @media all and (max-width: 1200px) {
            width: 1000px;
            height: auto;
            left: 15%;
        }
        @media all and (max-width: 890px){
            width: 850px;
        }
        @media all and (max-width: 750px){
            top: 5%;
            width: 1000px;
            left: -22%;
        }
        @media all and (max-width: 670px){
            left: -25%;
        }
        @media all and (max-width: 640px){
            left: -30%;
        }
        @media all and (max-width: 610px){
            left: -35%;
        }
        @media all and (max-width: 580px){
            left: -40%;
        }
        @media all and (max-width: 540px){
            left: -45%;
        }
        @media all and (max-width: 520px){
            left: -50%;
        }
        @media all and (max-width: 490px){
            left: -55%;
        }
        @media all and (max-width: 470px){
            left: -58%;
        }
        @media all and (max-width: 450px){
            left: -62%;
        }
        @media all and (max-width: 440px){
            left: -65%;
        }
        @media all and (max-width: 425px){
            left: -70%;
        }
        @media all and (max-width: 410px){
            left: -75%;
        }
        @media all and (max-width: 390px){
            left: -80%;
        }
        @media all and (max-width: 375px){
            left: -84%;
        }
        @media all and (max-width: 360px){
            left: -90%;
        }
        @media all and (max-width: 350px){
            left: -95%;
        }
        @media all and (max-width: 344px){
            left: -100%;
        }
        @media all and (max-width: 410px){
            
        }
        
        &-container {
            position: relative;
            height: 100%;
        }
        &-bg {
            position: absolute;
            z-index: 5;
            width: 100%;
            height: 100%;
            background: radial-gradient(57.55% 57.27% at 62.28% 62.35%, rgba(12, 12, 12, 0.10) 6.74%, #0C0C0C 89.23%, #0C0C0C 100%);
            @media all and (max-width: 750px){
                height: 100%;
            }
        } 
        &-video {
            position: relative;
            z-index: 3;
            video {
                height: 590px;
                @media all and (max-width: 890px){
                    height: 540px;
                }
            }

        }
    }
}