@import "../../styles/variables.scss";
@import "../../styles/mixin.scss";

.discuss {
    margin: 160px 0;
    position: relative;
    display: flex;
    align-items: center;
    @media all and (max-width: 1250px) {
        margin: 80px 0;

    }
    &__bg {
        width: 110%;
        height: 100%;
        position: absolute;
        z-index: 2;
        left: -70px;
        background: url('../../assets/img/bg-keyFeatures.png') no-repeat center center;
        background-size: contain;
        height: 612px;
        @media all and (max-width: 768px) {
            width: 800px;
            left: -30%;
            top: -40%;
        }
        @media all and (max-width: 560px) {
            left: -45%;
        }
        @media all and (max-width: 420px) {
            left: -70%;
        }
    }
    &__content {
        @include container(840px);
        position: relative;
        z-index: 5;
        padding: 0 30px 18px;
        
        @media all and (max-width: 1250px) {
            margin-top: 100px;
            width: 488.5px;
            padding: 0;
        }
        @media all and (max-width: 768px) {
            padding: 0 16px;
        }
        h2 {
            max-width: 580px;
            font-size: 68px;
            line-height: 110%;
            letter-spacing: -1.36px;
            @media all and (max-width: 1250px) {
                font-size: 56px;
                line-height: 90%;
                letter-spacing: -1.12px;
            }
            @media all and (max-width: 768px) {
                font-size: 36px;
                letter-spacing: -0.72px;
            }
        }
        h3 {
            margin: 35px 0 45px;
            font-size: 42px;
            line-height: 90%;
            letter-spacing: -0.84px;
            @media all and (max-width: 1250px) {
                font-size: 26px;
                line-height: 90%;
                letter-spacing: -0.52px;
                margin: 22px 0 29px 0;
            }
            @media all and (max-width: 768px) {
                font-size: 24px;
                letter-spacing: -0.48px;
                margin: 32px 0 24px 0;
            }
        }
    }
    &__from {
        @include grid(12px);
        @media all and (max-width: 1250px) {
            gap: 7.5px;
        }
        @media all and (max-width: 768px) {
            gap: 12px;
        }
        &-inp,
        &-error {
            padding: 19px 30px;
            border-top: 1px solid rgba(113, 71, 141, 0.80);
            border-bottom: 1px solid rgba(113, 71, 141, 0.80);
            background: rgba(113, 71, 141, 0.10);
            backdrop-filter: blur(10px);
            @media all and (max-width: 1250px) {
                padding: 12px 0 12px 18.50px;
                align-items: center;
            }
            @media all and (max-width: 768px) {
                padding: 20px 16px;
            }
            input {
                font-family: Helvetica Neue !important;
                outline: none;
                background: none;
                border: none;
                color: $color-text-counter;
                font-size: 20px;
                line-height: 110%;
                letter-spacing: -0.4px;
                @media all and (max-width: 1250px) {
                    font-size: 12px;
                    letter-spacing: -0.24px;
                }
                @media all and (max-width: 768px) {
                    font-size: 18px;
                    line-height: 110%;
                    letter-spacing: -0.36px;

                }
            }
        }
        button {
            padding: 16px;
            font-family: SF Pro Display;
            font-size: 16px;
            font-weight: 500;
            line-height: 100%;
            letter-spacing: -0.16px;
            border-radius: 8px;
            background: $background-btn-gradient;
            @media all and (max-width: 768px) {
                margin-top: 12px;
            }
        }
    }
}