@import "../../styles/variables.scss";
@import "../../styles/mixin.scss";

.keyFeatures {
    position: relative;
    display: flex;
    align-items: center;    
    &__bg {
        width: 110%;
        height: 100%;
        position: absolute;
        left: -70px;
        background: url('../../assets/img/bg-keyFeatures.png') no-repeat center center;
        background-size: contain;
        height: 612px;
        @media screen and (max-width: 1200px) {
            height: 380px;
            top: -120px;
        }
        @media screen and (max-width: 880px) {
            top: 0;
        }
    }
    &__content {
        @include container(1380px);
        height: 412px;
        padding-bottom: 18px;
        @media screen and (max-width: 1200px) {
            height: 230px;
        }
        @media screen and (max-width: 880px) {
            height: 500px;
            padding: 0;
        }
        @media screen and (max-width: 380px) {
            height: 595px;
        }
        
        h2 {
            font-size: 68px;
            line-height: 110%;
            letter-spacing: -1.36px;
            margin-bottom: 44px;
            @media screen and (max-width: 1200px) {
                font-size: 42px;
                letter-spacing: -0.84px;
                margin-bottom: 28.375px;
            }
        }
        &-title {
            @media screen and (max-width: 880px) {
                display: none;
            }
        }
    }
    &__info {
        &-block {
            @include flex(center, row);
            flex-wrap: wrap;
            gap: 20px;
            @media screen and (max-width: 1200px) {
                gap: 0;
                justify-content: space-between;
            }
            @media screen and (max-width: 880px) {
                display: none;
            }
        }
        &-none {
            display: none; 
            @media screen and (max-width: 880px) {
                @include container(345px);
                display: flex;
                flex-direction: column;
            }
            p {
                font-size: 14px;
                letter-spacing: -0.28px;
            }
            &-last:last-child {
                width: 100%;
                margin-top: 12px;
                font-size: 14px;
                letter-spacing: -0.28px;
            }
            div {
                display: flex;
                flex-wrap: wrap;
                gap: 12px;
                @media screen and (max-width: 380px) {
                    justify-content: center;
                }
                p {
                    width: 166px;
                    height: 118px;
                    padding: 15.323px 15.323px 0 15.323px;
                    font-size: 14px;
                    letter-spacing: -0.28px;
                    @media screen and (max-width: 380px) {
                        width: 100%;
                        font-size: 16px;
                        height: 90px;
                    }
                }
            }
        }
        p {
            padding: 24px;
            font-size: 20px;
            line-height: 110%;
            letter-spacing: -0.4px;
            border-radius: 8px;
            background: linear-gradient(10deg, rgba(32, 4, 70, 0.30) -6.15%, rgba(79, 38, 101, 0.30) 44.7%, rgba(169, 103, 160, 0.30) 116.9%);
            backdrop-filter: blur(10px);
            display: flex;
            width: 260px;
            min-height: 185px;
            @media screen and (max-width: 1200px) {
                width: 162.5px;
                height: 115.625px;
                padding: 15px 15px 22.625px 15px;
                font-size: 12px;
                line-height: 110%;
                letter-spacing: -0.24px;
            }
        }
    }
}