@import "../../styles/variables.scss";
@import "../../styles/mixin.scss";

.language {
    position: relative;
    width: 100%;
    button {
        cursor: pointer;
        display: flex;
        align-items: center;
        border-radius: 60px;
        background: rgba(255, 255, 255, 0.05);
        padding: 6px 10px;
        p {
            text-transform: uppercase;
            font-family: SF Pro Display;
            font-size: 16px;
            line-height: 12.5px;
            letter-spacing: -0.088px;
        }
        img {
            transition: .1s;
            width: 16px;
            height: 16px;
            margin-left: 3.75px;
        }
        .iconActive {
            transform: rotate(-180deg);
        }
        &:hover {
            background: rgba(255, 255, 255, 0.05);
        }
    }
    div {
        border-radius: 8px;
        background: rgba(99, 53, 114, 0.10);
        backdrop-filter: blur(12.5px);
        position: absolute;
        right: 0;
        width: 250px;
        padding: 12px;
        @include grid(4px);
        top: 45px;
        @media all and (max-width: 673px) {
            left: 0;
        }
        li {
            cursor: pointer;
            color: $color-text-btn;
            font-family: SF Pro Display;
            font-size: 16px;
            padding: 6px 8px;
            font-weight: 500;
            line-height: 100%;
            letter-spacing: -0.14px;
            border-radius: 4px;
        }
        .active {
            cursor: default;
            background: #4F2665;

        }
    }
    &__selected {
       
    }
    &__list {

    }
    &__list-none {
        display: none;
        
    }
}