@import "../../styles/variables.scss";
@import "../../styles/mixin.scss";

.contentVideos {
    @include container(988px);
    @include grid(235px);
    @media screen and (max-width: 1200px) {
        gap: 75.62px;
     }
    &__block {
        @media screen and (max-width: 1200px) {
            @include container(620px);
        }
        &-content {
            img {
                width: 100%;
            }
        }
        &-title {
            margin-left: 55px;
            margin-bottom: 40px;
            font-size: 48px;
            line-height: 90%;
            letter-spacing: -0.96px;
            @media screen and (max-width: 1200px) {
                font-size: 30px;
                font-weight: 400;
                letter-spacing: -0.6px;
                margin-left: 35px;
                margin-bottom: 24.80px;
            }
            @media screen and (max-width: 860px) {
                color: #FFF;
                font-size: 24px;
                letter-spacing: -0.48px;
            }
        }
        &-bottom {
            @include container(781px);
            @include flex(space-between, row);
            margin-top: 46px;
            @media screen and (max-width: 1200px) {
                padding-bottom: 55.73px;
            }
            @media screen and (max-width: 560px) {
                flex-direction: column;
                align-items: center;
                text-align: center;
                gap: 16px;
                margin-top: 35px;
                padding-bottom: 0;

            }
            p {
                max-width: 439px;
                font-size: 24px;
                line-height: 110%;
                letter-spacing: -0.48px;
                @media screen and (max-width: 1200px) {
                    max-width: 291px;
                    font-size: 16px;
                    letter-spacing: -0.32px;
                }
                @media screen and (max-width: 560px) {
                    font-size: 20px;
                    letter-spacing: -0.4px;
                }
            }
            button {
                font-size: 16px;
                font-weight: 500;
                line-height: 100%;
                letter-spacing: -0.16px;
                padding: 16px 50.5px;
                border-radius: 8px;
                background: $background-btn-gradient;
                @media screen and (max-width: 1200px) {
                    padding: 10px 31.5px;
                }
            }
            button:hover {
                background: #3B194D;
                transition: .3s;
                border-radius: 8px;
            }
        }
    }
}