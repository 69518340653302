@import "../../styles/variables.scss";
@import "../../styles/mixin.scss";

.container {
    width: 100%;
    border-top: 1px solid rgba(122, 78, 151, 0.30);
    margin-top: 60px;
    padding: 64px 0 44px;
}

.footer {
    @include container(1440px);
    @include grid(30px);
    padding: 0 30px;
    @media screen and (max-width: 860px) {
        padding: 0 16px;
        justify-content: center;
    }
    &__top {
        @include flex(space-between, row);
        @media screen and (max-width: 860px) {
            flex-direction: column-reverse;
        }
        ul {
            opacity: 0.8;
            @include grid(14px);
            max-width: 630px;
            width: 100%;
            @media screen and (max-width: 1200px) {
                max-width: 394px;
                gap: 8.75px;
            }
            @media screen and (max-width: 860px) {
                max-width: 340px;
                gap: 14px;
                margin-bottom: 32px;
            }
            li {
                text-align: right;
                border-bottom: 1px solid rgba(122, 78, 151, 0.30);
                @media screen and (max-width: 860px) {
                    text-align: left;
                }
               a {
                   padding: 2px 0;
                    font-size: 16px;
                    line-height: 100%;
                    letter-spacing: -0.32px;
                    text-transform: uppercase;
                    @media screen and (max-width: 1200px) {
                        font-size: 10px;
                        letter-spacing: -0.2px;
                    }
               }
            }
        }
    }
    &__social {
        display: flex;
        align-items: flex-end;
        gap: 8px;
        a {
            width: 48px;
            height: 48px;
            padding: 12px;
            border-radius: 100px;
            background: rgba(95, 61, 118, 0.50);
            backdrop-filter: blur(10px);
            @media screen and (max-width: 1200px) {
                width: 30px;
                height: 30px;
                padding: 7.5px;
            }
            img {
                @media screen and (max-width: 1200px) {
                    width: 15px;
                    height: 15px;
                }
            }
        }
    }
    &__bottom {
        @include flex(space-between, row);
        padding: 30px 0 25px;
        opacity: 0.87;
        align-items: flex-end;
        @media screen and (max-width: 1200px) {
            padding: 18.75px 0 14.875px;
        }
        @media screen and (max-width: 860px) {
            flex-direction: column;
        }
        p {
            max-width: 632px;
            color:#FAFAFA;
            font-size: 16px;
            line-height: 120%;
            letter-spacing: -0.32px;
            @media screen and (max-width: 1200px) {
                max-width: 395px;
                font-size: 10px;
                letter-spacing: -0.2px;
            }
        }
        ul {
            @include grid(8px);
            @media screen and (max-width: 860px) {
                margin-top: 24px;
            }
            li {
                color: #FAFAFA;
                text-align: right;
                font-size: 16px;
                line-height: 120%;
                letter-spacing: -0.08px;
                @media screen and (max-width: 1200px) {
                    font-size: 10px;
                    letter-spacing: -0.05px;
                }
                @media screen and (max-width: 860px) {
                    text-align: left;
                }
            }
        }
    }
}