@import "../../styles/variables.scss";
@import "../../styles/mixin.scss";


.cryptoIcon {
    margin-top: 200px;
    max-width: 1440px;
    width: 100%;
    &__content {
        position: relative;
        .bg {
            position: absolute;
            left: -5px;
            z-index: 10;
            top: -5px;
            width: 101%;
            height: 102%;
            background: linear-gradient(90deg, #000 0%, rgba(19, 15, 22, 0.00) 19.29%, rgba(12, 12, 12, 0.58) 83.64%, #000 100%);
        }
    }
    @media screen and (max-width: 1200px) {
        margin-top: 120px;
    }
    @media screen and (max-width: 860px) {
        margin-top: 0;
        position: relative;
        z-index: 5;
        @include flex(space-between, column);
        align-items: center;
    }
    @media screen and (max-width: 420px) {
        align-items: flex-start;
    }
    &__content {
        height: auto;
        position: relative;
        @include grid(16px);
        div {
            width: auto;
        }
    }
    &__title {
        max-width: 527px;
        margin-bottom: 40px;
        margin-left: 281px;
        font-size: 48px;
        line-height: 110%;
        letter-spacing: -0.96px;
        @media screen and (max-width: 1200px) {
            margin-left: 145px;
            margin-bottom: 25px;
            max-width: 330px;
            font-size: 30px;
            line-height: 110%;
            letter-spacing: -0.6px;
        }
        @media screen and (max-width: 860px) {
            margin-left: 0;
            margin-right: 60px;
            font-size: 32px;
            letter-spacing: -0.64px;
        }
        @media screen and (max-width: 420px) {
            margin-right: 0;
        }
    }
    img {
        width: 55px;
        @media screen and (max-width: 950px) {
            width: 45px;
        }
    }
}