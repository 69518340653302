@import "../styles/variables.scss";
@import "../styles/mixin.scss";
.home {
    @include grid(200px);
    padding: 0 30px;
    padding-bottom: 200px;
    @include container(1440px);
    position: relative;
    background-color: #000;
    @media screen and (max-width: 1200px) {
        gap: 124px;
    }
    @media screen and (max-width: 992px) {
        padding: 0 20px;
    }
    @media screen and (max-width: 860px) {
        gap: 64px;
        background-color: #000;
    }
    @media screen and (max-width: 673px) {
        padding: 0 16px 25px;
    }
    &__mainScreen{
        background-color: $body-background-dark;
    }
    &__invoice {
        @include container(1380px);
        margin-top: 120px;
        @media screen and (max-width: 860px) {
            margin-top: 55px;
            height: 500px;
        }
        video {
            @media screen and (max-width: 860px) {
                width: 820px;
                position: absolute;
                left: 1.5%;
            }   
            @media screen and (max-width: 840px) {
                left: 1%;
            }
            @media screen and (max-width: 800px) {
                left: 0;
            }
            @media screen and (max-width: 780px) {
                left: -3%;
            }
            @media screen and (max-width: 750px) {
                left: -5%;
            }
            @media screen and (max-width: 679px) {
                left: -10%;
            }
            @media screen and (max-width: 650px) {
                left: -15%;
            }
            @media screen and (max-width: 600px) {
                left: -20%;
            }
            @media screen and (max-width: 550px) {
                left: -25%;
            }
            @media screen and (max-width: 520px) {
                left: -30%;
            }
            @media screen and (max-width: 500px) {
                left: -35%;
            }
            @media screen and (max-width: 460px) {
                left: -40%;
            }
            @media screen and (max-width: 430px) {
                left: -45%;
            }
            @media screen and (max-width: 420px) {
                left: -50%;
            }
            @media screen and (max-width: 400px) {
                left: -55%;
            }
            @media screen and (max-width: 380px) {
                left: -60%;
            }
            @media screen and (max-width: 370px) {
                left: -65%;
            }
            @media screen and (max-width: 350px) {
                left: -70%;
            }
            @media screen and (max-width: 339px) {
                left: -75%;
            }
            @media screen and (max-width: 400px) {
                
            }
        }
    }
}